import React from "react";
import LpDemo from "../../../page-components/LpDemo";
import { lineOfBusiness } from "../../../constants";

export default function DemoPageSideKick() {
  return (
    <LpDemo
      leadType="lead-gen"
      dataLayerEventNameAppendix="-lead"
      formSubHeader="Save time and boost sales with SpotOn Retail, the all-in-one point-of-sale solution designed to let you do it all."
      formId="368fc7fd-6e19-450c-8bca-9a03976d20d9"
      useShortForm
      phoneNumber="+13035240930"
      phoneText="(303) 524-0930"
      imageName="retail-lp-demo.jpg"
      thankYouPath="/lp/retail-a/thank-you"
      lineOfBusiness={lineOfBusiness.RETAIL_AND_SERVICES}
    />
  );
}
